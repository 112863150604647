<template>
  <CCol>
    <CCard>
      <CCardHeader>
        <div class="row">
          <CCol sm="6" class="align-self-center">
            <strong>{{ $lang.skills.crud.other }}</strong>
          </CCol>
        </div>
      </CCardHeader>
      <CCardBody>
        <v-server-table
            :columns="columns"
            url="skills/other/list"
            :options="options"
            ref="myTable"
        >
          <template #status="data">
            <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
          </template>
          <template #title="data">
            <span :title="data.row.title">{{
                trimfunction(data.row.title, 20)
              }}</span>
          </template>
          <template #industry="data">
            <span :title="data.row.industry.title">{{
                trimfunction(data.row.industry.title, 20)
              }}</span>
          </template>
          <template #actions="data">
            <CButton
                color="danger"
                v-c-tooltip="$lang.buttons.general.crud.delete"
                v-on:click="deletePopup(data.row.id)"
                v-if="checkPermission('delete-skills')"
            >
              <i class="fas fa-trash"></i>
            </CButton>
            <CButton
                color="danger"
                v-c-tooltip="$lang.buttons.general.crud.move"
                v-on:click="movePopup(data.row.id)"
            >
              <i class="fas fa-pencil-alt"></i>
            </CButton>
            <CButton color="warning" v-c-tooltip="$lang.buttons.general.crud.merge"
                     v-on:click="mergePopup(data.row.id)">
              <i class="fas fa-pencil-alt"></i>
            </CButton>
          </template>
        </v-server-table>
      </CCardBody>
    </CCard>
  </CCol>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import {industry, skills} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {Maxlength} from "../../store/maxlength";
import store from "../../store/store";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "OtherSkill",
  mixins: [Mixin],
  data() {
    return {
      json_data: [],
      industryLists: [],
      json_label: {},
      json_title: "",
      messageColor: "success",
      submitted: false,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      activePage: 1,
      module: skills,
      moduleIndustry: industry,
      skills: {
        title: "",
        industryId: "",
        isActive: 1,
        isDefault: 0,
      },
      maxlength: {
        title: Maxlength.skills.title,
      },
      columns: ["industry", "title", "isDefault", "status", "actions"],
      data: [],
      options: {
        headings: {
          industry: this.$lang.skills.table.industry,
          title: this.$lang.skills.table.title,
          isDefault: this.$lang.skills.table.isDefault,
          status: this.$lang.common.table.isActive,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["title"],
        sortable: ["title"],
        filterable: ["title"],
      },
    };
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
    store.commit("showLoader", false);
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.data.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.dismissCountDownS = 10;
                  self.messageColor = "success";
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDownS = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.alertMessage = data;
            self.messageColor = "danger";
            self.dismissCountDownS = 10;
          });
        }
      });
    },
    movePopup(item) {
      let self = this;
      axios
          .get('skills/move/' + item)
          .then(function (response) {
            if (response.data.status === 200) {
              self.$refs.myTable.refresh();
              self.alertMessage = response.data.message;
              self.dismissCountDownS = 10;
              self.messageColor = "success";
            } else {
              self.$refs.myTable.refresh();
              self.alertMessage = response.data.message;
              self.messageColor = "danger";
              self.dismissCountDownS = 10;
            }
          }).catch(function (error) {
        let data;
        if (error.response.data.error) {
          data = error.response.data.error.toString();
        } else {
          data = error.response.data.message;
        }
        self.alertMessage = data;
        self.messageColor = "danger";
        self.dismissCountDownS = 10;
      });
    },
    mergePopup(item, index) {
      this.$router.push({path: `merge/${item}`});
    },
  },
};
</script>
